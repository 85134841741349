import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom";
import '../styles/ProductPage.css';
import $ from 'jquery';



class ProductPage extends Component {

    state = {
        item: {},
        img1: true,
        size: 'XS',
        quantity: 1
    }

    itemCarousel = ()=>{
        if (this.state.img1){
            $('#'+this.props.location.state.id).attr("src", this.props.location.state.img2);
                this.setState({
                 img1: false
            })
        }
        else{
            $('#'+this.props.location.state.id).attr("src", this.props.location.state.img1);
                this.setState({
                 img1: true
            })
        }
    }


    handleSize = event =>{
        this.setState({size : event.target.value})
    }

    handleQuantity = event =>{
        this.setState({quantity : event.target.value})
    }

    render() {
        return (
            <div className="product-page">
                <div className="product-page-container">
                    <div className="product-page-image">
                        <div onClick={this.itemCarousel} className="left"><span className="fa fa-arrow-left"></span></div>
                        <div onClick={this.itemCarousel} className="right"><span onClick={this.itemCarousel} className="fa fa-arrow-right"></span></div>
                        <img id={this.props.location.state.id} src={this.props.location.state.img1} className="card-img-top img-shop-item" alt={this.props.location.state.title} />
                    </div>
                    <div className="product-page-desciption">
                        <h1>{this.props.location.state.title}</h1>
                        <strong>{this.props.location.state.price}.00€</strong>
                        <div className="size">
                            <label htmlFor="size">Choose your size : </label>
                            <select value={this.state.size} onChange={this.handleSize}>
                                <option value="XS">XS</option>
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                            </select>
                        </div>
                        <div className="quantity">
                            <label htmlFor="quantity">Quantity : </label>
                                <select value={this.state.quantity} onChange={this.handleQuantity}>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                        </div>
                        <button onClick={()=>this.props.addToCart(this.props.location.state.price, this.props.location.state.title, this.props.location.state.img1, this.props.location.state.img2, this.props.location.state.alt, this.props.location.state.id, this.state.size, this.state.quantity)} className="btn btn-danger">Add to cart</button>
                        <Link className='btn btn-primary'to="/shopping-cart">Your cart <span className="fas fa-shopping-cart"></span></Link>
                        <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 458.57 404.26">
                            <path className="cls-1" d="M430,461.24c0,4,.1,7.7,0,11.36a9.78,9.78,0,0,1-9.55,9.38c-19.14.65-38.33-15.41-41.57-35a83.63,83.63,0,0,1-1.14-13.68q-.09-173.76-.05-347.51V78.74h21V428.28C398.69,452.41,405.28,459.57,430,461.24Z" transform="translate(-158.97 -78.24)"/>
                            <path className="cls-1" d="M159.74,223.44c1.42-5.76,2.21-10.44,3.74-14.85,8.13-23.56,22.76-42.34,41.92-57.17,30.66-23.75,65.77-36.18,103.87-40.81-18.68,12.67-36.94,25.63-49,45.78a129.22,129.22,0,0,0-18.73,65.47C214,207.1,187.29,207.31,159.74,223.44Z" transform="translate(-158.97 -78.24)"/>
                            <path className="cls-1" d="M352.84,222.73c-29.14-15.23-57.73-15-86.33-.72-4-44.59,31-93.32,86.33-110.69Z" transform="translate(-158.97 -78.24)"/>
                            <path className="cls-1" d="M616.77,223.44c-1.43-5.76-2.22-10.44-3.74-14.85-8.14-23.56-22.77-42.34-41.92-57.17-30.66-23.75-65.77-36.18-103.87-40.81,18.68,12.67,36.93,25.63,49,45.78A129.22,129.22,0,0,1,535,221.86C562.55,207.1,589.21,207.31,616.77,223.44Z" transform="translate(-158.97 -78.24)"/>
                            <path className="cls-1" d="M423.67,222.73c29.14-15.23,57.72-15,86.33-.72,4-44.59-31-93.32-86.33-110.69Z" transform="translate(-158.97 -78.24)"/>
                            <rect id="rain-one" className="cls-1" x="264.7" y="177.44" width="20.07" height="32.14"/>
                            <rect id="rain-two" className="cls-1" x="304.85" y="177.49" width="20.07" height="32.09"/>
                        </svg>
                    </div>
                </div>
                    <div className="product-page-details">
                        <h2>Product detail</h2>
                        <p>This T-shirt is as close to perfect as can be.</p>
                        <p>Soft, comfortable and durable, this is a definite must-own.</p>
                        <p>100% cotton.</p>
                        <p>Wide range of sizes from XS-XL.</p>
                        <p>Fairly produced, certified and triple audited.</p>
                        <p>Double stitched, reinforced seams at shoulder, sleeve, collar and waist.</p>
                        <p>Optimized for beautiful brilliance across all printing methods.</p>
                        <p>Imported, processed and printed in the U.S.A.</p>
                    </div>
            </div>
        )
    }
}

export default withRouter(ProductPage)
