import React, { Component } from 'react'
import ShopItem from './ShopItem'
import '../styles/Carousel.css'
import '../styles/Page.css'
import '../styles/Footer.css'

class Women extends Component {

    state ={
        layerImg: "../images/women-layer.jpg",
        shopItem: [
            {
                id: 'white-women-heart',
                img1: '/images/white-women-heart-front.png', 
                img2: '/images/white-women-heart-side.png', 
                idImg1: 'white-women-heart-front', 
                idImg2: 'white-women-heart-side',
                alt: 'White Tee', 
                title: 'White Tee', 
                price: 19.00
            },
            {
                id: 'grey-women-heart',
                img1: '/images/grey-women-heart-front.png', 
                img2: '/images/grey-women-heart-side.png', 
                idImg1: 'grey-women-heart-front', 
                idImg2: 'grey-women-heart-side',
                alt: 'Grey Tee', 
                title: 'Grey Tee', 
                price: 19.00
            },
            {
                id: 'pink-women-chest',
                img1: '/images/pink-women-chest-front.png', 
                img2: '/images/pink-women-chest-side.png', 
                idImg1: 'pink-women-chest-front', 
                idImg2: 'pink-women-chest-side',
                alt: 'Pink Tee', 
                title: 'Pink Tee', 
                price: 19.00
            },
            {
                id: 'white-women-chest',
                img1: '/images/white-women-chest-front.png', 
                img2: '/images/white-women-chest-side.png', 
                idImg1: 'white-women-chest-front', 
                idImg2: 'white-women-chest-side',
                alt: 'White Tee', 
                title: 'White Tee', 
                price: 19.00
            },
        ]
    }
    
    render() {
        return (
            <div className="page">
                <div className="layer" style={{backgroundImage: `url(${this.state.layerImg})`}}></div>
                <div className="container text-center">
                    <div className="shop">
                        {
                            this.state.shopItem.map((item, index)=>{
                                return(
                                    <ShopItem 
                                        key={index} 
                                        id={item.id}
                                        img1={item.img1} 
                                        img2={item.img2} 
                                        idImg1={item.idImg1} 
                                        idImg2={item.idImg2} 
                                        alt={item.alt} 
                                        title={item.title} 
                                        price={item.price} 
                                        color={item.color}
                                        scrollToTop={this.props.scrollToTop}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Women
