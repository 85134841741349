import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/ShoppingCart.css'
import ShopItemContext from './ShopItemContext'


class ShoppingCart extends Component {
    state = {
        value: this.context.items,
        totalPrice: 0,
        totalQuantity: 0
    }


    total = ()=>{
        const prices = this.state.value
            var sum = 0
            for (let i = 0; i < prices.length; i++){
                sum += parseInt(prices[i].price*prices[i].quantity)
                this.setState({
                    totalPrice: sum
                })
            }
    }

    quantity = ()=>{
        const quantity = this.state.value
            var sum = 0
            for (let i = 0; i < quantity.length; i++){
                sum += parseInt(quantity[i].quantity)
                this.setState({
                    totalQuantity: sum
                })
            }
    }

    componentDidUpdate(){
    }
    componentDidMount(){
        this.total()
        this.quantity()
        this.setState({
            value: this.context.items
        })
    }
    render() {

        // const cart = JSON.parse(localStorage.myCart)

        if (this.state.value.length === 0){
            return(
                <div className="shopping-cart">
                    <div className="shopping-cart-empty">
                        <h2>Your cart is empty :(</h2>
                        <h4><Link onClick={this.props.scrollToTop} to="/">Go Shopping</Link></h4>
                    </div>
                </div>
            )
        }else{
            
            return (
                <ShopItemContext.Consumer>
                    {
                        data =>{
                            return (
                            <div className="shopping-cart">
                                <h2>Your cart :</h2>
                                {
                                    
                                // cart.map((item, index)=>{
                                this.state.value.map((item, index)=>{
                                    return(
                                        <div key={index} className="shopping-cart-item">
                                            <Link to={{
                                                pathname: `/product/${item.id}`,
                                                state: { 
                                                    title: item.title,
                                                    price: parseFloat(item.price),
                                                    img1: item.img1,
                                                    img2: item.img2,
                                                    id: item.id
                                                }
                                            }}><img src={item.img1} alt={item.alt}/></Link>
                                            <div className="shopping-cart-item-description">
                                                <p className="title">{item.title}</p>
                                                <p className="quantity"><strong>Quantity : </strong>{item.quantity}</p>
                                                <p className="size"><strong>Size : </strong>{item.size}</p>
                                                <div className="shopping-cart-item-close">
                                                    <strong onClick={()=>{this.props.removeItem(index, item.quantity)}}> Remove this item </strong>
                                                </div>
                                            </div>
                                            <div className="shopping-cart-item-description-price">
                                                <p className="price"><strong>Unit price : </strong>{item.price}.00€</p>
                                                <p className="total-item"><strong>Total : </strong>{item.price * item.quantity}.00€</p>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                <div className="total-order">
                                <div className="order-summary"><strong>ORDER SUMMARY</strong></div>
                                <div className="item-numbers"><strong>Items : </strong>{this.state.totalQuantity}</div>
                                    <div className="total-order-price"><strong>Total : </strong><span>{this.state.totalPrice}.00€</span></div>
                                    <button className="btn checkout btn-dark">Checkout</button>
                                    <p className="continue-shopping">.. or <Link onClick={this.props.scrollToTop} to="/">Continue Shopping</Link> !</p>
                                </div>

                            </div>
                            )
                        }
                    }
                </ShopItemContext.Consumer>
            )
        }
    }
}

ShoppingCart.contextType = ShopItemContext

export default ShoppingCart
