import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/NavMenu.css'
import ShopItemContext from './ShopItemContext'


class NavMenu extends Component {
    state = {
        menuOpen: false,
    }

    
    
    openMenu = ()=>{
        const hamburgerButton = document.getElementsByClassName('nav-menu-hamburger')
        const menuDown = document.getElementsByClassName('nav-menu-ul-sm')
        if (window.innerWidth < 1025){
            if(!this.state.menuOpen){
                hamburgerButton[0].classList.toggle('open');
                menuDown[0].style.transform = "translateX(0%)"
                this.setState({
                    menuOpen: true
                })
            }else{
                hamburgerButton[0].classList.toggle('open');
                menuDown[0].style.transform = "translateX(-100%)"
                this.setState({
                    menuOpen: false
                })
            }
        }
        this.props.scrollToTop()
    }
    
    componentDidMount(){
        window.addEventListener('scroll', this.scroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll);
    }

    scroll = (e)=>{
        if (window.innerWidth > 1024){
            const nav = document.getElementsByClassName('nav-menu')
            const navUl = document.getElementsByClassName('nav-menu-ul')
            let offsetTop = window.scrollY
            if (offsetTop < 100){
                nav[0].style.opacity = '1'
                nav[0].style.height = '140px'
                navUl[0].style.opacity = '1'
                navUl[0].style.margin = '1em'
            }
            if (offsetTop > 100){
                nav[0].style.opacity = '.3'
                nav[0].style.height = '90px'
                navUl[0].style.opacity = '0'
                navUl[0].style.margin = '0'
            }
        }
    }

    render() {

        let value = this.context

        return (
            <ShopItemContext.Consumer>
                {
                    data =>{
                        return (
                        <div className="nav-menu">
                            <div className="nav-menu-logo">
                                <Link onClick={this.openMenu} to="/">
                                    <svg id="umbrella" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 458.57 404.26">    
                                        <path id="path-one" className="cls-1" d="M430,461.24c0,4,.1,7.7,0,11.36a9.78,9.78,0,0,1-9.55,9.38c-19.14.65-38.33-15.41-41.57-35a83.63,83.63,0,0,1-1.14-13.68q-.09-173.76-.05-347.51V78.74h21V428.28C398.69,452.41,405.28,459.57,430,461.24Z" transform="translate(-158.97 -78.24)"/>
                                        <path className="cls-1" d="M159.74,223.44c1.42-5.76,2.21-10.44,3.74-14.85,8.13-23.56,22.76-42.34,41.92-57.17,30.66-23.75,65.77-36.18,103.87-40.81-18.68,12.67-36.94,25.63-49,45.78a129.22,129.22,0,0,0-18.73,65.47C214,207.1,187.29,207.31,159.74,223.44Z" transform="translate(-158.97 -78.24)"/>
                                        <path className="cls-1" d="M352.84,222.73c-29.14-15.23-57.73-15-86.33-.72-4-44.59,31-93.32,86.33-110.69Z" transform="translate(-158.97 -78.24)"/>
                                        <path className="cls-1" d="M616.77,223.44c-1.43-5.76-2.22-10.44-3.74-14.85-8.14-23.56-22.77-42.34-41.92-57.17-30.66-23.75-65.77-36.18-103.87-40.81,18.68,12.67,36.93,25.63,49,45.78A129.22,129.22,0,0,1,535,221.86C562.55,207.1,589.21,207.31,616.77,223.44Z" transform="translate(-158.97 -78.24)"/>
                                        <path className="cls-1" d="M423.67,222.73c29.14-15.23,57.72-15,86.33-.72,4-44.59-31-93.32-86.33-110.69Z" transform="translate(-158.97 -78.24)"/>
                                        <rect id="rain-one" className="cls-1" x="264.7" y="177.44" width="20.07" height="32.14"/>
                                        <rect id="rain-two" className="cls-1" x="304.85" y="177.49" width="20.07" height="32.09"/>
                                    </svg>
                                </Link>
                                <Link onClick={this.openMenu} to="/">
                                    <svg id="typo" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 539.66 243.91">
                                        <path className="cls-1" d="M162.58,250.49c14.61-13.25,32.72-14.22,47-1.75,7.84,6.86,11.39,15.88,11.41,26.06q.18,80.59,0,161.18a15.49,15.49,0,0,1-.37,2.3H204.54v-6c0-51.33.05-102.65-.11-154A30.3,30.3,0,0,0,202,265.92,16.24,16.24,0,0,0,184,257a18.05,18.05,0,0,0-14.21,15.68,46.49,46.49,0,0,0-.12,5.15V438.27H153.21v-6.18c0-51.84.07-103.68-.14-155.52a24.33,24.33,0,0,0-3.2-12.11c-3.77-6.13-9.9-9-17.27-7.4-7.22,1.53-11.63,6.27-13.37,13.35a33,33,0,0,0-.71,7.64q0,77,0,154v6.32h-16.2c-.1-1.87-.27-3.54-.27-5.2q0-78.27,0-156.55c0-24.45,19.21-41,41.72-35.39C150.13,242.81,155.84,247.08,162.58,250.49Z" transform="translate(-102 -194.97)"/>
                                        <path className="cls-1" d="M335.92,438.34H319.76v-6.17q0-77.48,0-154.95c0-9.88-4.49-17.29-11.83-19.67-11.41-3.71-22.63,4.64-22.84,17.22-.17,10.29,0,20.59,0,30.89V438.27H268.84c-.1-1.64-.26-3.13-.26-4.63,0-53-.09-106,0-159.07.06-26.54,27.16-42.85,50.17-30.19,11.46,6.31,17.33,16.64,17.37,29.57.2,53.88.08,107.76.07,161.65C336.22,436.42,336,437.24,335.92,438.34Z" transform="translate(-102 -194.97)"/>
                                        <path className="cls-1" d="M403.43,240.34h16.13a24.75,24.75,0,0,1,.34,3q0,80.07,0,160.12c0,13.58-5.72,24.35-17.67,31-22.35,12.49-49.17-3.39-50-29.55-.36-11.31-.1-22.65-.1-34V240.43h16.48v5.79q0,77.23.07,154.46a29.79,29.79,0,0,0,1.67,10.52,16.82,16.82,0,0,0,17,10.77,17.59,17.59,0,0,0,15.41-13.76,38.37,38.37,0,0,0,.67-8.17q0-76.71,0-153.43Z" transform="translate(-102 -194.97)"/>
                                        <path className="cls-1" d="M506.58,309.48v73c0,6.86-.1,13.73,0,20.59.23,11,7.67,19,17.51,19s17.07-8.13,17.32-19.2c0-2.22,0-4.44,0-7.12H557.6c1.51,13.84-1.14,26.31-12.52,35.48-10.75,8.66-23,9.88-35.3,4.22-12.81-5.89-19.46-16.78-19.55-30.79q-.39-65.14,0-130.27c.11-19.17,15.55-34.3,34-34.25s33.45,15.32,33.75,34.49c.18,11.45,0,22.91,0,34.9Zm34.77-16.27c0-7,.42-13.56-.1-20a17.85,17.85,0,0,0-16.2-16.52c-7.67-.52-15.92,4.95-17.39,13.1-1.36,7.58-1,15.48-1.38,23.42Z" transform="translate(-102 -194.97)"/>
                                        <path className="cls-1" d="M641.44,283.33H625c0-2.77,0-5.44,0-8.11C624.76,265,617.44,257,608,256.69c-9.16-.32-17.39,7.61-17.68,17.75-.33,11.15-.17,22.32,0,33.47.1,7.59,4,12.91,10.69,16.24,8.27,4.12,16.81,7.76,24.77,12.38,9.84,5.71,15,15,15.48,26.18.57,14.39.42,28.84.1,43.25a33.78,33.78,0,0,1-33.45,32.92c-18.44.21-33-14.12-34.12-33.46-.53-9.55-.53-9.55,8.9-9.55h7.17c.33,4.39.33,8.18.94,11.86a17.17,17.17,0,0,0,18.26,14.59c8.17-.53,15.68-8.23,15.82-16.85.23-13.56.38-27.13,0-40.68-.27-8.67-5.25-14.42-13.3-17.84a184.13,184.13,0,0,1-21.24-10.19c-11.12-6.49-16.29-16.8-16.5-29.54q-.25-16.22,0-32.44c.28-19.38,15.45-34.73,34.09-34.69s33.4,15.63,33.56,35.16C641.46,277.79,641.44,280.32,641.44,283.33Z" transform="translate(-102 -194.97)"/>
                                        <path className="cls-1" d="M483.86,422.36c-1.29-.17-1.8-.28-2.31-.3-15.88-.56-22.11-7.06-22.11-23.06V256.58h23.89V240H459.44V195H442.9v2c.45,16,.46,76,.06,96.37q0,55,0,110c0,18.92,13.11,33.57,31.26,35.27,4.54.43,9.09-2.07,9.53-5.76C484.19,429.32,483.86,425.77,483.86,422.36Z" transform="translate(-102 -194.97)"/>
                                        <path className="cls-1" d="M252.65,438.38H236.89v-198h15.76Z" transform="translate(-102 -194.97)"/>
                                        <rect className="cls-1" x="134.89" width="15.76" height="25.24"/>
                                    </svg>
                                </Link>
                            </div>

                            <ul className="nav-menu-ul">
                                <li>
                                    <Link onClick={this.openMenu} className="nav-menu-ul-link" to="/">HOME</Link>
                                </li>
                                <span className="nav-menu-ul-separator">|</span>
                                <li>
                                    <Link onClick={this.openMenu} className="nav-menu-ul-link" to="/women">WOMEN</Link>
                                </li>
                                <span className="nav-menu-ul-separator">|</span>
                                <li>
                                    <Link onClick={this.openMenu} className="nav-menu-ul-link" to="/men">MEN</Link>
                                </li>
                                <span className="nav-menu-ul-separator">|</span>
                                <li>
                                    <Link onClick={this.openMenu} className="nav-menu-ul-link" to="/shopping-cart"><span className="fas fa-shopping-cart"></span><span> ({value.itemNumbers})</span></Link>
                                </li>
                            </ul>

                            <ul className="nav-menu-ul-sm">
                                <li>
                                    <Link onClick={this.openMenu} className="nav-menu-ul-link" to="/">HOME</Link>
                                </li>
                                <span className="nav-menu-ul-separator">|</span>
                                <li>
                                    <Link onClick={this.openMenu} className="nav-menu-ul-link" to="/women">WOMEN</Link>
                                </li>
                                <span className="nav-menu-ul-separator">|</span>
                                <li>
                                    <Link onClick={this.openMenu} className="nav-menu-ul-link" to="/men">MEN</Link>
                                </li>
                            </ul>

                            <Link className="nav-menu-ul-link small-screen-cart" to="/shopping-cart"><span className="fas fa-shopping-cart"></span><span> ({value.itemNumbers})</span></Link>
                            <div className="nav-menu-hamburger" onClick={this.openMenu}>
                                <div className="nav-menu-hamburger-bar"></div>
                                <div className="nav-menu-hamburger-bar"></div>
                                <div className="nav-menu-hamburger-bar"></div>
                            </div>
                        </div>
                        )
                    }
                }
            </ShopItemContext.Consumer>
        )
    }
}

NavMenu.contextType = ShopItemContext

export default NavMenu

