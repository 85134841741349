import React, { Component } from 'react'

class Carousel extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            slide: true,
        }
    }
    
    render() {
        return (
            <div className="carousel diapo">
                <div id="carousel-cont" className="carousel-cont">
                    {
                        this.props.imgSource.map((img, index)=>{
                            return(
                                <div key={index} className="carousel-img" style={{backgroundImage: 'url('+ img.source +')'}}></div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Carousel
