import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/ErrorPage.css'

function ErrorPage() {
    return (
        <div className="error-page">
            <h1>Oops !</h1> 
            <h2>Page not found !</h2>
            <h3>Go back to the <Link to="/"><strong>Home</strong></Link> page ...</h3>
        </div>
    )
}

export default ErrorPage
