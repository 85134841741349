import React from 'react'


const shopItemContext = {
    itemNumbers: 0,
    items: [],
    totalPrice: 0
}

const ShopItemContext = React.createContext(shopItemContext)

export default  ShopItemContext 