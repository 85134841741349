import React from 'react';
import ShopItemContext from './components/ShopItemContext'
import NavMenu from './components/NavMenu'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ArrowUp from './components/ArrowUp'
import Home from './components/Home'
import Footer from './components/Footer'
import Women from './components/Women'
import Men from './components/Men'
import ProductPage from './components/ProductPage'
import ErrorPage from './components/ErrorPage'
import ShoppingCart from './components/ShoppingCart';



class App extends React.Component{
  
  state = {
    itemNumbers: 0,
    items: [],
    totalPrice: 0,
    nom : "ced"
  }

  scrollToTop = ()=>{
    window.scrollTo(0, 0);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.scroll);
  }

  componentDidUpdate(){
    if (this.state.itemNumbers < 0){
      this.setState({
        itemNumbers: 0
      })
    }
  }

  scroll = ()=>{
      const arrow = document.getElementById('arrow')        
      let offsetTop = window.scrollY

      if (offsetTop > 120){
          arrow.style.opacity = .3
      }
      else{
          arrow.style.opacity = 0
      }
  }


  addToCart = (price, title, img1, img2, alt, id, size, quantity)=>{
    let itemArray = this.state.items

    itemArray.push({price: price, title: title, img1: img1, img2: img2, alt: alt, id: id, size: size, quantity: quantity})
    this.setState({
      itemNumbers : (parseInt(this.state.itemNumbers) +parseInt(quantity)),
      items:  itemArray
    })
    localStorage.setItem('myCart', this.state.items)
  }

  removeItem = (index, quantity)=>{
    const itemArray = this.state.items
    const removeIndex = itemArray.map(function(item) { return item.id; }).indexOf(index);
    itemArray.splice(removeIndex, 1);
    this.setState({
      itemNumbers : (parseInt(this.state.itemNumbers) - parseInt(quantity)),
      items:  itemArray
    })
  }

  render(){
    return (
        <div className="App">
          <BrowserRouter>
            <ArrowUp scrollToTop={this.scrollToTop}/>
            <ShopItemContext.Provider value={this.state}>
              <NavMenu scrollToTop={this.scrollToTop}/> 
            </ShopItemContext.Provider>
            <Switch>
              <Route exact path="/" component={() => <Home scrollToTop={this.scrollToTop} /> } />
              <Route path="/women" component={() => <Women scrollToTop={this.scrollToTop} /> }/>
              <Route path="/men" component={() => <Men scrollToTop={this.scrollToTop} /> }/>
              <Route path="/product/:productId" component={() => <ProductPage addToCart={this.addToCart} scrollToTop={this.scrollToTop}/> }/>
              <ShopItemContext.Provider value={this.state}>
              <Route path="/shopping-cart" component={() => <ShoppingCart scrollToTop={this.scrollToTop} removeItem={this.removeItem}/> }/>
              </ShopItemContext.Provider>
              <Route component={ErrorPage} />
            </Switch>
            <Footer scrollToTop={this.scrollToTop}/>
          </BrowserRouter>
        </div>
    );
  }
}

export default App;
