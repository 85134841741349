import React from 'react'
class ArrowUp extends React.Component{

    render(){
        return (
            <div onClick={this.props.scrollToTop} id="arrow" className="arrow"><span className="fas fa-arrow-up"></span></div>
        )
    }
}

export default ArrowUp