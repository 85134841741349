import React, { Component } from 'react'
import Carousel from './Carousel'
import ShopItem from './ShopItem'
import { Link } from 'react-router-dom'
import '../styles/Carousel.css'
import '../styles/Page.css'
import '../styles/Footer.css'
import $ from 'jquery'

class Home extends Component {

    
    state = {
        carouselImg: [
            {source : "/images/carousel-1.jpg"},
            {source : "/images/carousel-2.jpg"},
        ],
        shopItem: [
            {   
                id: 'black-men-heart',
                idImg1: 'black-men-heart-front', 
                idImg2: 'black-men-heart-side', 
                img1:'/images/black-men-heart-front.png',
                img2:'/images/black-men-heart-side.png', 
                alt: 'Men Tee Minutes', 
                title: 'Men Tee', 
                price: 19, 
                color: ['Black', 'White']  
            },
            {
                id: 'white-women-heart',
                idImg1: 'white-women-heart-front', 
                idImg2: 'white-women-heart-side',
                img1: '/images/white-women-heart-front.png',
                img2: '/images/white-women-heart-side.png', 
                alt: 'Women Tee Minutes', 
                title: 'Women Tee', 
                price: 19.00, 
                color: ['White', 'Grey']  
            }
        ],
        scrollTop: 0,
        linkImg:[
            {source : "/images/women-link.png", url : '/women', txt: 'WOMEN'},
            {source : "/images/men-link.png", url : '/men', txt: 'MEN'}
        ],




    }

    imageWidth = ()=>{
        const womenMenWidth = $('.women-men-links').width();
        $('.women-men-links').css({'height':womenMenWidth+'px'});
    }

    componentDidMount(){
        window.addEventListener('scroll', this.scroll);
        $(window).resize(this.imageWidth)
        this.imageWidth()
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll);
    }

    scroll = ()=>{
        const moi = document.getElementById('moi')
        const beYou = document.getElementById('be-you')
        
        let offsetTop = window.scrollY
        let beYouOffset = beYou.offsetTop - window.innerHeight

        if (offsetTop > beYouOffset){
            beYou.classList.add('animated-text')
            moi.classList.add('animated-img')
        }
    }
    render() {

        return (
            <div className="page">
                <Carousel imgSource={this.state.carouselImg}/>
                <h1 className="col-12">NEW ARRIVALS</h1>
                <div className="container text-center">
                    <div className="shop">
                        {
                            this.state.shopItem.map((item, index)=>{

                                return(
                                    <ShopItem 
                                        params={item}
                                        key={index} 
                                        id={item.id}
                                        img1={item.img1} 
                                        img2={item.img2} 
                                        idImg1={item.idImg1} 
                                        idImg2={item.idImg2} 
                                        alt={item.alt} 
                                        title={item.title} 
                                        price={item.price} 
                                        color={item.color}
                                        scrollToTop={this.props.scrollToTop}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <div className="women-men">
                    <h1>ALL OUR PRODUCTS</h1>
                        <div className="women-men-links-cont">
                        {
                            this.state.linkImg.map((item, index)=>{
                                return (
                                <Link className="women-men-links" key={index} to={item.url}> 
                                    <div onClick={this.props.scrollToTop} className="women-men-links-div" style={{backgroundImage: 'url(' + item.source + ')'}} >
                                        <div className="women-men-links-txt-cont">
                                            <span>{item.txt}</span>
                                        </div>
                                    </div>
                                </Link>
                                )
                            })
                        }
                        </div>
                </div>
                <div className="section-logo">
                    <div className="text-image">
                        <div id="be-you">
                            <p>MINUTES</p>
                            <p>OF</p>
                            <p>FREEDOM</p>
                        </div>
                        <img id="moi" src='/images/test.png' alt='moi'/>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Home
