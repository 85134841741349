import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/ShopItem.css'
import $ from 'jquery'

class ShopItem extends Component {

    state = {
        img1: true,
        img: this.props.img1
    }

    itemCarousel = ()=>{
        if (this.state.img1){
            $('#'+this.props.id).attr("src", this.props.img2);
                this.setState({
                 img1: false
            })
        }
        else{
            $('#'+this.props.id).attr("src", this.props.img1);
                this.setState({
                 img1: true
            })
        }
    }

    render() {
        return (
            <div className="shop-item ">
                <div className="shop-item-container">
                        <div className="shop-item-img-container">
                            <div onClick={this.itemCarousel} className="left"><span className="fa fa-arrow-left"></span></div>
                            <div onClick={this.itemCarousel} className="right"><span onClick={this.itemCarousel} className="fa fa-arrow-right"></span></div>
                            <Link to={{
                                pathname: `/product/${this.props.id}`,
                                state: { 
                                    title: this.props.title,
                                    price: this.props.price,
                                    img1: this.props.img1,
                                    img2: this.props.img2,
                                    id: this.props.id,
                                    alt: this.props.alt
                                }
                            }}>
                                        <img onClick={this.props.scrollToTop} id={this.props.id} src={this.state.img} className="card-img-top img-shop-item" alt={this.props.alt} />
                                </Link>
                        </div>
                    <div className="shop-item-description">
                        <h3>{ this.props.title }</h3>
                        <p>{ this.props.price } €</p>
                    </div>
                </div>
            </div>
            // </div>
        )
    }
}

export default ShopItem
