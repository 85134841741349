import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'


class Footer extends Component {
    render() {
        return (
            <div className="footer fixed-bot text-center zind">
                <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 458.57 404.26">
                    <path className="cls-1" d="M430,461.24c0,4,.1,7.7,0,11.36a9.78,9.78,0,0,1-9.55,9.38c-19.14.65-38.33-15.41-41.57-35a83.63,83.63,0,0,1-1.14-13.68q-.09-173.76-.05-347.51V78.74h21V428.28C398.69,452.41,405.28,459.57,430,461.24Z" transform="translate(-158.97 -78.24)"/>
                    <path className="cls-1" d="M159.74,223.44c1.42-5.76,2.21-10.44,3.74-14.85,8.13-23.56,22.76-42.34,41.92-57.17,30.66-23.75,65.77-36.18,103.87-40.81-18.68,12.67-36.94,25.63-49,45.78a129.22,129.22,0,0,0-18.73,65.47C214,207.1,187.29,207.31,159.74,223.44Z" transform="translate(-158.97 -78.24)"/>
                    <path className="cls-1" d="M352.84,222.73c-29.14-15.23-57.73-15-86.33-.72-4-44.59,31-93.32,86.33-110.69Z" transform="translate(-158.97 -78.24)"/>
                    <path className="cls-1" d="M616.77,223.44c-1.43-5.76-2.22-10.44-3.74-14.85-8.14-23.56-22.77-42.34-41.92-57.17-30.66-23.75-65.77-36.18-103.87-40.81,18.68,12.67,36.93,25.63,49,45.78A129.22,129.22,0,0,1,535,221.86C562.55,207.1,589.21,207.31,616.77,223.44Z" transform="translate(-158.97 -78.24)"/>
                    <path className="cls-1" d="M423.67,222.73c29.14-15.23,57.72-15,86.33-.72,4-44.59-31-93.32-86.33-110.69Z" transform="translate(-158.97 -78.24)"/>
                    <rect className="cls-1" x="264.7" y="177.44" width="20.07" height="32.14"/>
                    <rect className="cls-1" x="304.85" y="177.49" width="20.07" height="32.09"/>
                </svg>
                <div className="row">
                    <div className="footer-links col-lg-6 col-md-12 col-sm-12">
                        <NavLink onClick={this.props.scrollToTop} to="/"><p>HOME</p></NavLink>
                        <NavLink onClick={this.props.scrollToTop} to="/women"><p>WOMEN</p></NavLink>
                        <NavLink onClick={this.props.scrollToTop} to="/men"><p>MEN</p></NavLink>
                    </div>
                    <div className="footer-links col-lg-6 col-md-12 col-sm-12">
                        <a href="/"><p>FACEBOOK</p></a>
                        <a href="/"><p>INSTAGRAM</p></a>
                        <a href="/"><p>TWITTER</p></a>
                    </div>
                </div>

                <span className="copyright">© 2020 - Minutes Clothing</span>
            </div>
        )
    }
}

export default Footer
